<template>
  <div id="container">  
    <div class="head">
      <el-row :gutter="0">
        <el-col :span="7" class="kefan" style="height: 35%">
          <h4 style="text-align: center;padding:0;margin:5px">最畅销的房源排名</h4>
          <ul class="paiming">
            <li v-for="(item, index) in paiming" :key="index">
              <el-image
                v-if="index <= 2"
                style="width: 20px; height: 20px;position:relative;right:10px"
                :src="imgurl + 'img/' + (index + 1) + '.png'"
                :fit="'contain'"
              >
              </el-image>
              <span v-if="index > 2" class="order" style="padding-left: 20px"
                >{{ index + 1 }}.</span
              >
              <span v-else class="order">{{ index + 1 }}.</span>
              <span class="text">{{ item.region }}：</span>
              <span class="rooms">{{ item.number }}/{{ item.total }}套</span>
              <span>({{ item.bfb }}%)</span>
            </li>
          </ul>
        </el-col>
        <el-col :span="16" :push="1"  class="right" style="height:35%">
          <div id="main" style="width: 100%; height: 230px"></div>
        </el-col>
      </el-row>
    </div>
    <h3 class="title" style="margin-left: 7px">
      空置房源
      <el-divider></el-divider>
    </h3>
    <div class="kefan tjdata">
      <div class="ziduan">
        <div class="selection">
          <el-select v-model="value" clearable placeholder="请选择园区" @change="changevalue">
            <el-option
              v-for="(item, index) in option"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="export">
               <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
               <el-button type="success" style="width:150px" plain @click="exportexcel(value)">导出excel</el-button>
          </div>
        </div>
        <div class="textline">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="100"> </el-table-column>
            <el-table-column prop="floor" label="楼栋楼层" width="200">
            </el-table-column>
            <el-table-column prop="office" label="门牌名称" width="200">
            </el-table-column>
            <el-table-column
              prop="type"
              label="物业类型"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="state" label="租赁状态" width="200">
            </el-table-column>
            <el-table-column prop="area" label="面积(㎡)"> </el-table-column>
            <el-table-column prop="long" label="空置天数"> </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="page">
        <span class="demonstration"></span>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pagesize"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import * as echarts from "echarts";
export default {
  data() {
    return {
      paiming: [],
      imgurl: this.$houtaiurl,
      category: [],
      lineData: [],
      barData: [],
      value: "zhw",
      tableData: [],
      pagesize:10,
      currentPage:1,
      total:0,
      title:"空置房统计",
      option: [
        {
          value: "zhw",
          label: "智慧湾",
        },
        {
          value: "qlf",
          label: "七立方",
        },
        {
          value: "fcx",
          label: "凤城巷",
        },
        {
          value: "fhy",
          label: "凤凰园",
        },
        {
          value: "thgj",
          label: "同和国际大厦",
        },
        {
          value: "zhs",
          label: "智慧树",
        },
        {
          value: "tkgj",
          label: "天科国际大厦",
        },
        {
          value: "zhf",
          label: "智慧坊",
        },
        {
          value: "zhq",
          label: "智慧桥",
        },
        {
          value: "bdw",
          label: "半岛湾",
        },
        {
          value: "mzy",
          label: "明珠园",
        },
        {
          value: "zhx",
          label: "智慧线",
        },
        {
          value: "gsy",
          label: "智慧线冠生园",
        },
        {
          value: "khl",
          label: "科慧楼",
        },
      ],
    };
  },
  methods: {
    // 导出excel
      exportexcel(val){
        axios({
          method:'post', 
          url: this.$host+this.$url.excel,
          headers: {
            'Content-Type':'application/json',
           
          },
          responseType:'blob',
          data:{
            yuanqu:val
          }
        }).then((res)=>{
          let url=window.URL.createObjectURL(new Blob([res.data]));
          let link=document.createElement('a')
          link.download=this.value+'空置表.xls'
          link.href=url
          link.click()
        })
      },
    // 下拉框值发生改变时
      changevalue(){
        this.getpaiming();
        this.getemptyrooms()
      },
    // 分页处理
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.currentPage = val
        this.getemptyrooms()
      },
    // 获取空置房源
    getemptyrooms() {
      axios({
        method: "post",
        url: this.$host + this.$url.empty,
        data: {
          yuanqu: this.value,
          currentPage:this.currentPage,
          pagesize:this.pagesize
        },
      }).then((res) => {
        console.log(res.data);
        this.total = res.data.max_nums
        this.tableData = res.data.data
      });
    },
    // 图表
    getecharts() {
      console.log(this.paiming);
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      let category = [];
      let lineData = [];
      let barData = [];
      for (var item of this.paiming) {
        category.push(item.region);
        lineData.push(item.total);
        barData.push(item.number);
      }
      // option
      option = {
        backgroundColor: "#0f375f",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["可出租套数", "已出租套数"],
          textStyle: {
            color: "#ccc",
          },
        },
        xAxis: {
          data: category,
          axisLine: {
            lineStyle: {
              color: "#ccc",
            },
          },
        },
        yAxis: {
          splitLine: { show: false },
          axisLine: {
            lineStyle: {
              color: "#ccc",
            },
          },
        },
        series: [
          {
            name: "可出租套数",
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 15,
            data: lineData,
          },
          {
            name: "已出租套数",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              borderRadius: 5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#14c8d4" },
                { offset: 1, color: "#43eec6" },
              ]),
            },
            data: barData,
          },
          {
            name: "最大出租数",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            itemStyle: {
              borderRadius: 5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(20,200,212,0.5)" },
                { offset: 0.2, color: "rgba(20,200,212,0.2)" },
                { offset: 1, color: "rgba(20,200,212,0)" },
              ]),
            },
            z: -12,
            data: lineData,
          },
          // {
          //   name: "dotted",
          //   type: "pictorialBar",
          //   symbol: "rect",
          //   itemStyle: {
          //     color: "#0f375f",
          //   },
          //   symbolRepeat: true,
          //   symbolSize: [12, 4],
          //   symbolMargin: 1,
          //   z: -10,
          //   data: lineData,
          // },
        ],
      };

      option && myChart.setOption(option);
    },
    getpaiming() {
      axios({
        method: "get",
        url: this.$host + this.$url.ty + "?yuanqu=" + this.value,
      }).then((res) => {
        // console.log(res.data)
        this.paiming = res.data;
        this.getecharts();
      });
    },
    toggleSelection(rows) {
      if(rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  mounted() {
    this.getpaiming();
    this.getemptyrooms();
  },
};
</script>
<style scoped>
.title .el-divider--horizontal {
  width: 80px;
  margin: 5px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.selection .export{
  position: absolute;
  right: 50px;
}
.selection{
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.page{
  display: flex;
  align-items: right;
  justify-content: right;
  padding-top: 10px;
}
.textline {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}
.el-divider {
  margin-top: 5px;
}
.right {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}
.head {
  height: 260px;
}
.tjdata {
  margin-top: 10px;
}
.head li {
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
}
.head ul {
  list-style: none;
  padding: 0;
  margin: 0 0 2px 0;
}
.paiming li {
  /* padding-left: 50px; */
  /* line-height: 20px; */
  padding-left: 15%;
  font-size: 13px;
}
.paiming li:nth-child(1) {
  font-size: 13px;
  font-weight: 700;
  color: #ffd600;
}
.paiming li:nth-child(2) {
  font-size: 13px;
  font-weight: 700;
  color: #abb5c1;
}
.paiming li:nth-child(3) {
  font-size: 13px;
  font-weight: 700;
  color: #cda787;
}
.order {
  margin-right: 10px;
}
.text {
  margin-right: 10px;
}
</style>